import axios from 'axios'
import { useLoginKeeper } from '@/components/composables/loginKeeper'; 
import { useUserStore } from '@/stores/user'

/*----Global AJAX Setting---------*/
axios.defaults.timeout= 600000;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// axios.defaults.headers.post['Content-Type'] = 'application/json';
const $http=axios;

// actions
const beforeApiCall=(method,api)=>{
  
  // consoleLog("is caling...",method,api);
}
const consoleLog=(text,json,data)=>{
  
   if(process.env.NODE_ENV=='development'||window.location.href.indexOf('test')>=0){
      console.log(text,json,data);
    }
}


const getHttpConfig=(utkn)=>{
  axios.defaults.headers['source'] = process.env.VUE_APP_SOURCE

  return {
    headers: {
      authorization :`Bearer ${utkn}`
    }
  };
}

export function useApiCall() {
    const store = useUserStore()
    const loginKeeper = useLoginKeeper()
    function postApi (api,json={}) {
        return new Promise((resolve, reject) => {
            beforeApiCall('post',api);
            $http.post(api,json,getHttpConfig(store.user.utkn))
            .then( ({data}) =>{
                    resolve(data);
                
            })
            .catch( (error)=> {
              let isNext = errorHandle(error.response)
              if(isNext != null){
                resolve(isNext);
              }            
            });
        })
    }
    function putApi (api,json={}) {
        return new Promise((resolve, reject) => {
            beforeApiCall('put',api);
            $http.put(api,json,getHttpConfig(store.user.utkn))
            .then( ({data}) =>{
                resolve(data);
            })
            .catch( (error)=> {
              let isNext = errorHandle(error.response)
              if(isNext != null){
                resolve(isNext);
              }            
            });
        })
    }
    function getApi (api,json={},other_conf = {}) {
        return new Promise((resolve, reject) => {
            let conf=getHttpConfig(store.user.utkn);
            conf.params = {...json};
            conf = Object.assign(conf, other_conf);
            beforeApiCall('get',api);
            $http.get(api,conf)
            .then( ({data}) =>{
              resolve(data)
            })
            .catch( (error)=> {
              let isNext = errorHandle(error.response)
              if(isNext != null){
                resolve(isNext);
              }
            });
        })
    }
    function deleteApi (api,json={}) {
        return new Promise((resolve, reject) => {
            let conf=getHttpConfig(store.user.utkn);
            conf.params = {...json};
            beforeApiCall('delete',api);
            $http.delete(api,conf)
            .then( ({data}) =>{
                resolve(data);
            })
            .catch( (error)=> {
              let isNext = errorHandle(error.response)
              if(isNext != null){
                resolve(isNext);
              }
            });
        })
    }
    const errorHandle = (error )=>{
      
        consoleLog("error",error);

        error.status = parseInt(error.status);
        if(error.status==401){
          if(error.data.code == 6){
            consoleLog("verify needed");
            if(store.router.currentRoute.value.fullPath.indexOf('/verify')>=0){
              store.router.go(0);
            }else{
              loginKeeper.gotoVerify({mode:'dialog'})

            }
            return null
          }else{
            loginKeeper.gotoLogin({mode:'dialog'})
            return null
          }
        }else if(error.status==429){ 
          alert("超過請求限制次數，請稍後再試")
          return null
        } 
      //   else if(error.status==403){
      //     commit('dialogBox',{dialog:true,option:{title:"權限不足",message:"你沒有權限瀏覽此內容喔！"}},{root:true});
      //   } 
      //   else if(error.status==406||(error.status==400&&error.data.code == 2)){
      //     commit('dialogBox',{dialog:true,option:{title:"參數錯誤",message:"Oops!你的資料可能有錯誤！請檢查後再試一次"}},{root:true});
      //   }
      //   else{ 
      //     commit('dialogBox',{dialog:true,option:{title:`伺服器錯誤${error.status}`,message:"Oops!伺服器發生錯誤！請聯繫系統管理員或稍後再試"}},{root:true});
      //   }
        return error.data;
      };
    return{ 
        getApi,postApi,putApi,deleteApi
    }
}