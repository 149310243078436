
export default [
  
    {
        path: '/group',
        name: 'group',
        component: () => import('@/views/group/MyGroupPage.vue'),
        meta: { 
            requiresAuth: true ,
            requireCompany:true,
            permissions:['group.#.edit'],
            seo:{
                title:"我的小組列表",
            },
            toolbar:{
                title:'我的小組列表',
                showBack:true,
                styleReverse:true,
                backTo:{name:'me'}
            }
        },
        
    },
    {
        path: '/group/detail/:group_id',
        name: 'group/detail',
        props:true,
        component: () => import('@/views/group/GroupDetailPage.vue'),
        meta: { 
            requiresAuth: true ,
            requireCompany:true,
            permissions:['group.#.edit'],

            seo:{
                title:"小組資料",
            },
            toolbar:{
                title:'小組資料',
                showBack:true,
                styleReverse:true,
                backTo:{name:'group'}
            }
        },
    },
    {
        path: '/group/member/:user_id',
        name: 'group/member',
        props: true,
        component: () => import('@/views/group/GroupMemberDetailPage.vue'),
        meta: { 
            requiresAuth: true ,
            requireCompany:true,
            permissions:['group.#.edit'],

            seo:{
                title:"組員資料",
            },
            toolbar:{
                title:'組員資料',
                showBack:true,
                styleReverse:true,
                backTo:{name:'group'}
            }
        },
    },
    {
        path: '/group-leader-redeem-link',
        name: 'group-leader-redeem-link',
        component: () => import('@/views/group/GroupLeaderRedeemAuthPage.vue'),
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"綁定小組長授權"
            }
        },
    },
    {
        path: '/group-member-redeem-link',
        name: 'group-member-redeem-link',
        component: () => import('@/views/group/GroupMemberRedeemAuthPage.vue'),
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"綁定小組員授權"
            }
        },
    },
    {
        path: '/node-redeem-auth-link',
        name: 'node-redeem-auth-link',
        component: () => import('@/views/group/GroupClusterRedeemAuthPage.vue'),
        meta: { 
            requiresAuth: true ,
            
            seo:{
                title:"綁定牧區負責人授權"
            }
        },
    },
];