
export default [
  
    {
        path: '/account/profile',
        name: 'account/profile',
        component: () => import('@/views/account/profile/ProfilePage.vue'),
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"我的檔案",
            }
        },
    },
    {
        path: '/me',
        name: 'me',
        component: () => import('@/views/account/MePage.vue'),
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"我的",
            }
        },
    },
   
];