import { toRefs, ref,onMounted,reactive, computed,markRaw } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useDialog } from 'primevue/usedialog';
import Login from '@/components/login/Login.vue';
import Register from '@/components/login/Register.vue';
import WelcomeBack from '@/components/login/WelcomeBack.vue';
import LoginCombinedPanel from '@/components/login/LoginCombinedPanel.vue';

const currFlow = reactive({
    step:'login',
    mode:'page',
    backTo:'/home'
})
const config = reactive({
    props:{
        // header:false,
        modal:true,
        closable:false,
        style:{ 'max-width': '500px', },
        breakpoints:{ '1080px': '50vw','780px': '60vw', '575px': '95vw' },
        draggable:false
    },

})
const dialogIns = ref(null)
export function useLoginKeeper(conf) {
    const router = useRouter()
    const route = useRoute()
    const dialog = useDialog();
    const currSetting = computed(()=>{
        return {
            component : LoginCombinedPanel,
            headerComponenet: WelcomeBack
        }
    })
    const setMode = (value ) => {
        currFlow.mode = value
    }
    const _open = ( ) => {
        if( dialogIns.value == null){
            dialogIns.value = dialog.open(currSetting.value.component, {
                props:config.props,
                templates: {
                    header: currSetting.value.headerComponenet
                },
            });
        }
        
    }
    const setBackTo = () => {
        if(currFlow.mode == 'page'){
            //頁面模式，確認網址參數
            let backto = router.currentRoute.value.query.backto || ''

            let previousRoute = router.options.history.state.back
            let whiteList = ['/logout','/login_return','/verify','/404page','/register','/forgot-password','/login']
            let inList = false

            if(backto.length>0){
                currFlow.backTo = backto;

            }else if(previousRoute){
                whiteList.forEach(x => {
                    if(previousRoute.indexOf(x) >=0){
                        inList = true
                    }
                });

                if(inList  == false){
                    currFlow.backTo = encodeURIComponent(previousRoute);
                }
            }

        }else{
            // 彈窗模式，回到當前頁面
            let currr_location= location.pathname + location.search 
            // currFlow.backTo = encodeURIComponent(route.fullPath);
            currFlow.backTo = encodeURIComponent(currr_location);
            
        }
    }
    const gotoLogin = (value = {}) => {
        currFlow.step = 'login'
        if(value.mode){
            setMode(value.mode)
        }
        if(currFlow.mode == 'page'){
            router.push({name:'login'});

        }else if(currFlow.mode == 'dialog'){
            setBackTo()
            _open()
        }
    };
    const gotoVerify = (value = {}) => {
        currFlow.step = 'verify'
        if(value.mode){
            setMode(value.mode)
        }
        if(currFlow.mode == 'page'){
            router.push({name:'verify'});
        }else if(currFlow.mode == 'dialog'){
            setBackTo()
            _open()
        }
    };
    const gotoRegister = (value = {}) => {
        currFlow.step = 'register'
        if(value.mode){
            setMode(value.mode)
        }
        if(currFlow.mode == 'page'){
            router.push({name:'register'});
        }else if(currFlow.mode == 'dialog'){
            setBackTo()
            _open()
        }
    };
    const gotoForgotPassword = (value = {}) => {
        currFlow.step = 'forgot-password'
        if(value.mode){
            setMode(value.mode)
        }
        if(currFlow.mode == 'page'){
            router.push({name:'forgot-password'});
        }else if(currFlow.mode == 'dialog'){
            _open()
        }
    };
    const gotoLoginReturn = (access_token ) => {
        currFlow.step = 'verify'
        router.push({name:'login_return',
            query:{
                access_token:access_token,
                backto:currFlow.backTo
            }
        })
    };
    return {
        currFlow,currSetting,dialogIns:dialogIns.value,
        setMode,gotoLogin,gotoVerify,gotoRegister ,gotoForgotPassword,gotoLoginReturn,setBackTo
    };
}
